<template>
    <div >
        <div style="text-align: left;">
            <h2>查询文件资料</h2>
        </div>
        <Form ref="queryForm" :model="queryFormInline" label-position="right" :label-width="100" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <i-select v-model="queryFormInline.parentid">
                            <i-option value="" >全部</i-option>
                            <i-option value="0" key=0>通用资料</i-option>
                            <i-option v-if="modellist !=null" v-for="(item,index) in modellist" :value="item.id" :key="item.id">车型{{item.modelno}}资料</i-option>
                        </i-select>
                    </td>
                    <td align="right" width="23%">
                        <FormItem  label="资料名称:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="queryFormInline.title">
                            </i-input>
                        </FormItem>
                    </td>
                    <td align="left">
                        <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                            <Button type="primary" @click="handleQuery()" >查询</Button>    
                            <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <!-- 文件列表-->
        <div style="padding-top:10px">
            <Table :columns="tblcolumns" :data="tbldata" style="clear: both;">
            <template slot-scope="{ row }" slot="modelno">
                <strong v-if="row.publicfile">通用</strong>
                <strong v-else="row.publicfile">型号:{{ row.modelno }}</strong>
            </template>
            <template slot-scope="{ row,index }" slot="title">
                 <a class="tblitemtitle" @click="view(index)">   <strong > {{row.title}}</strong> </a>
                </template>
            <template slot-scope="{ row, index }" slot="action" >
                <Button type="success" size="small" @click="view(index)" style="margin-right: 5px" >下载</Button>
                <Button type="error" size="small" @click="remove(index)" style="margin-right: 5px" v-if="usercategory !='third'">删除</Button>
            </template>
            </Table>
            <div>
                <Page :total="queryFormInline.total" :page-size="queryFormInline.pageSize" show-total style="margin:6px auto;float:right"  @on-change="onpagechange"/>
            </div>
            <Card style="width: 100%;margin-top: 25px;clear: both;" v-if="usercategory != 'third'">
                <p slot="title">
                    <Icon type="ios-film-outline"></Icon>
                    上传文档资料
                </p>
                <Form ref="fileAddForm" :model="addFormInline" label-position="right" :label-width="100" style="margin:10px;border:1px solid lightgray;background:rgb(247, 246, 235);">
                    <table width="100%">
                        <tr>
                            <td align="left" width="13%">
                                <FormItem  label="文件归类:" style="margin-bottom:5px;margin-top:10px">
                                    <i-select v-model="addFormInline.parentid" @on-select="handleAddSelectModel">
                                        <i-option value="0" key=0>通用资料</i-option>
                                        <i-option v-for="(item,index) in modellist" :value="item.id" :key="item.id">车型{{item.modelno}}</i-option>
                                    </i-select>
                                </FormItem>
                            </td>
                            <td align="right" width="23%">
                                <FormItem  style="margin-bottom:5px;margin-top:10px">
                                    <Upload
                                        type="drag"
                                        multiple
                                        action="/api/params/files/upload"
                                        :data= "addFormInline"
                                        :on-success="handleSuccess"
                                        :before-upload="beforeUpload"
                                        >
                                        <div style="padding: 20px 0">
                                            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                                            <p>点击或拖拽文件进行上传</p>
                                        </div>
                                    </Upload>
                                </FormItem>
                            </td>
                        </tr>
                    </table>
                </Form>
            </Card>
        </div>
        <Modal v-model="showdeleteconfirm" title="删除文件" @on-ok="confirmDelete">确定要删除吗？</Modal>
    </div>
</template>

<script>
export default {
    name:'ziliao',
    data () {
            return {
                usercategory: this._self.$root.$store.state.LogonInfo.userCategory,
                showdeleteconfirm:false,
                deleteIndex:null,
                modellist:this._self.$root.$store.state.ModelListInfo,
                queryFormInline:{
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    parentid:null,
                    title:null,
                    total:0,
                    pageNum:1,
                    pageSize:10
                },
                addFormInline:{
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    parentid:null,
                    modelno:null,
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '文件归类',
                        slot: 'modelno',
                    },      
                    {
                        title: '文件名',
                        slot: 'title',
                    },          
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center'
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            console.log(this.modellist)
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                let postdata = JSON.parse(JSON.stringify(this.queryFormInline))
                for(var p in postdata){
                    if(postdata[p] == null || postdata[p] == ''){
                        delete postdata[p]
                    }
                }

                this.$axios({
                    method:'post',
                    url:"/api/params/files/query",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.queryFormInline.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            handleAdd(){ 
                if(this.addFormInline.parentid == null){
                    this.$Message.error({content:'标准名称不能为空！',duration:3})
                    return
                }
                if(this.addFormInline.filelist.length ==0){
                    this.$Message.error({content:'文件还未上传！',duration:3})
                    return
                }
                this.$axios({
                    method:'post',
                    url:"/api/params/files/add",
                    data: this.addFormInline,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res)
                        this.handleQuery()
                        this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            modify (index) {
                this.editmodeldata = this.tbldata[index]
                this.showeditmodel = true
            },
            view(index){
                let openurl = window.location.protocol+"//"+window.location.host+this.tbldata[index].filepath
                window.open(openurl, '_blank');
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/params/files/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            handleSuccess(response, file, fileList){
                this.addFormInline.filepath = response.data.filepath
                this.addFormInline.filename = response.data.originfilename
                this.$Message.success({content:"文件上传成功", duration:3})
                console.log(this.addFormInline)
                console.log(fileList)
            },
            beforeUpload(){
                if(this.addFormInline.parentid == null){
                    this.$Message.error({content:'请先选择文件归类',duration:3})
                    return false
                }

                console.log(this.addFormInline)
                return true
            },
            handleAddSelectModel(e){
                console.log(e.label +':'+e.value)

                for(let i=0;i<this.modellist.length;i++){
                    if(this.modellist[i].id == e.value){
                        this.addFormInline.modelno = this.modellist[i].modelno
                        break
                    }
                }
            },
            onpagechange(p){
                this.queryFormInline.pageNum = p
                this.handleQuery()
            }
        }
}
</script>

<style scoped>
    .tblitemtitle {
        cursor: pointer;
    }
    .ivu-card-head p, .ivu-card-head-inner{
        text-align: left;
    }
</style>